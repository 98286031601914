
<template>
  <div id="Block">
    <ore-pool-list />
  </div>
</template>

<script>
import orePoolList from "./Ore-Pool/index";

export default {
  // 引入的组件
  components: {
    orePoolList,
  },
  data() {
    return {
      currencyRank: {},
    };
  },
  methods: {
    // async getCurrencyRank() {
    //   await this.$axios.get("market/currency-rank").then((res) => {
    //     if (res.code === 0) this.currencyRank = res.data;
    //   });
    // },
  },
};
</script>

<style lang="less">
#Block {
  padding-bottom: 0 !important;
}
</style>