
<template>
  <div id="Ore-Pool-List">
    <div class="top title components">存储节点列表</div>
    <div class="bottom content" @scroll="list_scroll">
      <div
        id="Ore-Pool-Body"
        ref="Ore-Pool-Body"
        class="demo-infinite-container"
      >
        <a-list
          :data-source="list.item"
          :locale="$language.zhCN"
          :loading="list.loading"
        >
          <a-list-item
            slot="renderItem"
            slot-scope="item, index"
            :ref="`list-row-` + index"
          >
            <router-link
              :to="{ name: 'Ore-Pool-View', params: { id: item.address } }"
            >
              <a-spin
                tip="获取最新数据中。。。"
                :spinning="list.item_loading[index]"
              >
                <a-row
                  :class="`list-item-body list-item-body-${index}`"
                  :gutter="[0, 10]"
                >
                  <!-- <a-col :span="24">{{ list.item_loading[index] }}</a-col> -->
                  <a-col :span="24" class="node">
                    <span>节点名称: </span>
                    <span>{{ item.tag_name }}</span>
                  </a-col>
                  <a-col :span="24" class="node">
                    <span>节点ID: </span>
                    <span>{{ item.address }}</span>
                  </a-col>
                  <a-col :span="24">
                    <span>有效算力: </span>
                    <span>{{ item.quality_power }}</span>
                  </a-col>
                  <a-col :span="24">
                    <span>存储服务效率: </span>
                    <span>{{ item.mining_efficiency }}</span>
                  </a-col>
                  <a-col :span="24">
                    <span>算力增速: </span>
                    <span>{{ item.power_growth }}</span>
                  </a-col>
                  <a-col :span="24">
                    <span>出块奖励: </span>
                    <span>{{ item.total_rewards }}</span>
                  </a-col>
                  <a-col :span="24">
                    <span>幸运值: </span>
                    <span>{{ item.lucky_value }}</span>
                  </a-col>
                  <a-col :span="24">
                    <span>{{ item.key }}</span>
                  </a-col>
                  <a-col :span="24">
                    <a-button>点击查看详情</a-button>
                  </a-col>
                </a-row>
              </a-spin>
            </router-link>
          </a-list-item>
          <div v-if="list.loading" class="demo-loading-container">
            <a-spin />
          </div>
        </a-list>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { List, Pagination } from "ant-design-vue";
Vue.use(List);
Vue.use(Pagination);

import infiniteScroll from "vue-infinite-scroll";

let $ = {};
export default {
  directives: { infiniteScroll },
  beforeCreate() {
    $ = this.$;
  },
  // 数据
  data() {
    return {
      list: {
        item: [],
        item_loading: [],
        item_show: [],
        item_show_length: 2,
        loading: false,
        pagination: { page_index: 1, page_size: 10 },
      },
    };
  },
  // 方法
  methods: {
    async getOrePoolList() {
      const params = this.list.pagination;
      // 当滚动向下的时候， 通过 a.total / a.page_size < a.page_index 判断是否已经获取完所有数据
      if (params.total / params.page_size < params.page_index) return true;

      this.list.loading = true;
      let datas = await this.$Api.get().getMiners(params);
      this.list.loading = false;

      if (!datas) return false;

      this.list.item.push(...datas.miners);
      this.list.item_loading = new Array(this.list.item.length).fill(false);
      this.list.item_show = new Array(this.list.item.length).fill(false);
      for (let i = 0; i < this.list.item_show_length; i++)
        this.list.item_show[i] = true;
      this.list.pagination = datas.pagination;
    },
    list_scroll(e) {
      e;
    },
    setDomHeight() {
      const dom = $(this.$el);
      if (dom) {
        setTimeout(() => {
          dom.height(0);
          dom.height(dom.parent().height());
          dom.css({ maxHeight: "100%" });
          const dom2 = dom.children(".content");
          dom2.height(0);
          let height = 0;
          dom.children("*:not(.content)").each(function () {
            height += $(this).outerHeight();
          });
          dom2.height(dom.height() - height);
        }, 100);
      }
    },
  },
  mounted() {
    this.setDomHeight();
    this.getOrePoolList();
  },
};
</script>

<!-- 添加"scoped"属性，以限制CSS仅对该组件 -->
<style lang="less">
#Ore-Pool-List {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
    flex: 0 0 auto;

    &.bottom.content {
      flex: 0 1 auto;
      overflow: auto;
    }
  }

  .ant-list {
    min-height: 100%;

    .ant-list-items {
      > .ant-list-item {
        border: 0;

        .list-item-body {
          background-color: #3d447b;
          box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.2);
          padding: 0.5em 1em;
          border-radius: 0.5em;

          > .ant-col {
            display: flex;
            justify-content: space-between;
            align-content: flex-start;
            align-items: flex-start;
            // height: 3em;
            line-height: 1.6em;
            flex-wrap: wrap;

            &.node {
              > span {
                font-size: 1.6em;

                &:first-child {
                  font-weight: bold;
                  letter-spacing: 0.1em;
                  color: #dd3f5c;
                }
              }

              &:first-child {
                font-size: 1.2em;
              }
            }

            // &.node {
            //   > span {
            //     width: 100%;
            //   }
            // }

            > span {
              // width: 100%;
            }

            > span:last-child {
              color: #dcdcdc;
              text-align: right;
            }
          }

          > .ant-col + .ant-col {
            border-top: 1px solid rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
  }
}
</style>
