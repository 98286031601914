
<template>
  <!-- 中间页面,防止当前组件中的方法在子页面中执行 -->
  <index-page v-if="$route.name === 'Block'" />
  <router-view v-else :key="$route.path + Math.random()" />
</template>

<script>
// 避免子组件调用父组件东西
import indexPage from "./indexPage";
export default {
  components: {
    indexPage,
  },
};
</script>
